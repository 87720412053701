/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire old-app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";
@import '~leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
// Re-uses images from ~leaflet package
@import "@runette/leaflet-fullscreen/dist/leaflet.fullscreen.css";

// For gallery (full screen display) of photos
@import '~lightgallery/scss/lightgallery';

// For justified display of photos in grid
@import '~flickr-justified-gallery/dist/fjGallery.css';

@import "../node_modules/angular-calendar/css/angular-calendar.css";

@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import '~@ctrl/ngx-emoji-mart/picker';
